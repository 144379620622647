<template>
  <div
    class="profile-data-wrapper financial-graphs service-fee"
    v-show="!loading"
  >
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0 &&
        userPermissions.financial_graphs.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row">
          <div class="col">
            <router-link
              class="btn primary-btn"
              :to="{ name: 'FinancialGraphs' }"
              ><font-awesome-icon :icon="['fas', 'arrow-left']"
            /></router-link>
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <Select2
              id="filter-period"
              :options="filters.period.options"
              v-model="filters.period.value"
              class="select2-box"
              :resetOptions="false"
              @select="getServiceFee()"
            />
          </div>
          <div class="col">
            <Select2
              id="filter-connect-types"
              v-model="filters.profile.value"
              :options="filters.profile.options"
              class="select2-box"
              :resetOptions="false"
              :placeholder="`Connect Type`"
              @select="getServiceFee()"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="connects-chart">
          <h2 class="section-heading text-transform-uppercase">Service Fee</h2>
          <template v-if="!connectData">
            <div
              class="
                no-data-wrap
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <img src="@/assets/images/no-graph.png" alt="no-graph" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
          <template v-else>
            <chart-js
              :type="`line`"
              :datasets="connectChart.datasets"
              :labels="connectChart.labels"
              :options="connectChart.options"
            />
          </template>
        </div>
      </div>
      <div class="custom-table no-arrows">
        <table id="datatable">
          <thead>
            <tr>
              <th>Time Period</th>
              <!-- <th>Contract</th> -->
              <!-- <th>Profile</th> -->
              <th>Service Fee($)</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(connect, index) in connectData" :key="index">
              <tr>
                <td>{{ connect.x }}</td>
                <!-- <td>{{ connect.y.Contract }}</td> -->
                <!-- <td>{{ connect.y.ProfileName }}</td> -->
                <td>{{ Math.round(connect.y.TotalFee) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="profile-footer d-flex justify-content-end">
        <button class="primary-btn" type="button" @click="downloadCsv()">
          <font-awesome-icon :icon="['fa', 'download']" /> Download
        </button>
      </div>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        (userPermissions.financial_graphs.length === 0 ||
          (userPermissions.financial_graphs.length > 0 &&
            !userPermissions.financial_graphs.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import financialGraphService from "../services/financialGraph.service";
import common from "../mixins/common";
import ChartJs from "../components/ChartJs.vue";
import dataLabels from "chartjs-plugin-datalabels";

export default {
  components: { Select2, FontAwesomeIcon, ChartJs },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        period: {
          options: ["Monthly", "Annually"],
          value: "Monthly",
        },
        profile: {
          options: [],
          value: "All",
        },
      },
      connectData: [],
      connectChart: {
        labels: [],
        datasets: [
          {
            label: "Service Fee",
            data: [],
            borderColor: "#6fda45",
            backgroundColor: "#6fda45",
          },
        ],
        plugins: [dataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: { mode: null },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "",
            },
            datalabels: {
              backgroundColor: "transparent",
              borderRadius: 4,
              color: "#000",
              font: {
                weight: "800px",
                size: '16px "Montserrat", sans-serif',
              },
              padding: 0,
              formatter: function (value) {
                let finalValue = value;
                finalValue = Math.round(value);
                return `${finalValue}`;
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              tricks: {
                reverse: true,
              },
            },
            y: {
              grid: {
                drawBorder: false,
                display: true,
              },
              min: 0,
              ticks: {
                stepSize: 10,
              },
              title: {
                display: true,
                text: "Connects Utilized",
                font: {
                  color: "#333",
                  weight: "600",
                  size: '18px "Montserrat", sans-serif',
                },
              },
            },
          },
        },
      },
      sideModal: {
        connect: {
          add: {
            show: false,
          },
        },
      },
    };
  },
  async mounted() {
    await this.getServiceFee();
    this.loading = false;
  },
  methods: {
    async getServiceFee() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await financialGraphService.getServiceFee(
            vm.filters.period.value,
            vm.filters.profile.value
          );
          vm.connectData = response.service_fee_table_data.slice().reverse();
          vm.connectChart.labels = response.service_fee_lbs;
          vm.connectChart.datasets[0].data = response.service_fee_dts;
          vm.filters.profile.options = response.profiles;
          console.log(vm.filters.profile.options);
          vm.connectChart.options.plugins.title.text =
          vm.filters.profile.value + " (" + vm.filters.period.value + ")";

          var dT = $("#datatable").DataTable();
          dT.destroy();

          vm.$nextTick(function () {
            $("#datatable").DataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Service Fee - "+vm.filters.profile.value + " (" + vm.filters.period.value + ")",
                  extension: ".csv",
                },
              ],
              iDisplayLength: 25,
              bLengthChange: false,
              /* Disable initial sort */
              aaSorting: [],
              info: false,
            });
            $(".dt-buttons, .dataTables_filter").hide();
          });

          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.sideModal.connect.add.show = false;
      }, 300);
    },
    async openSideModal(action) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      if (
        vm.userPermissions &&
        vm.userPermissions.financial_graphs &&
        vm.userPermissions.financial_graphs.length > 0 &&
        vm.userPermissions.financial_graphs.includes("View")
      ) {
        if (
          action === "addConnect" &&
          vm.userPermissions.financial_graphs.includes("Add")
        ) {
          vm.sideModal.connect.add.show = true;
        }
        setTimeout(() => {
          document.body.classList.add("lmodal-open");
        }, 300);
      }
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  watch: {
    userPermissions: {
      handler: function () {
        this.getServiceFee();
      },
      deep: true,
    },
  },
};
</script>
